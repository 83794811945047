import { Action } from "@ngrx/store";

export enum PageActionType {
    UPDATE_PAGE = 'Update page'
}

export enum PayloadTypes {
    SHOPPE_ORDER = 'shoppeOrder',
    CARSPA_ORDER = 'carspaOrder',
    MECHANICAL_ORDER = 'mechanicalOrder',
    QUICKHELP_ORDER = 'quickhelpOrder'
}

export interface PageUpdatePayload {
    type: PayloadTypes,
    page: number
}

export class PageActionClass implements Action {
    readonly type: PageActionType

    constructor(public payload: PageUpdatePayload) {}
}

export type PageAction = PageActionClass