import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserValidationService {

  private intervalId: any;
  private checkInterval: number = 1800000; // 30 minutes in milliseconds
  //private checkInterval: number = 120000; // 2 minutes in milliseconds
  private subscription: Subscription | null = null;

  constructor(private http: HttpClient,private router: Router) {}

  startUserValidationCheck() {
    console.log("VALIDATION CHECK : started...")
    this.checkUserValidity(); // Call the function immediately
    this.intervalId = setInterval(() => {
      this.checkUserValidity();
    }, this.checkInterval);
  }

  checkUserValidity() {
    console.log("VALIDATION CHECK : started...")
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.resultData._id) {
      const userId = currentUser.resultData._id;
      const token = currentUser.resultData.userToken;
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
      this.subscription = this.http.get(`v1.0/admin/user/id/${userId}`, { headers })
        .subscribe((data: any) => {
          console.log("VALIDATION CHECK : USER VALIDATED")
        }, error => {
          console.error('VALIDATION CHECK :Error validating user:', error);
          sessionStorage.clear();
          localStorage.clear();
          this.router.navigate(['/login']);
        });
    }
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
