import { Action } from '@ngrx/store';
import { Notification } from '../models/notifications.model';
import { PendingUpdatePayload } from './order-status.action';

export enum NotificationActionType {
    SET_NOTIFICATIONS = 'Set Notifications',
    ADD_NOTIFICATION = 'Add Notification',
    READ_ALL = 'Read all notifications of a service'
}

interface ShoppePendingUpdatePayload {
    shoppe: number
}

interface CarspaPendingUpdatePayload {
    carspa: number
}

interface MechanicalPendingUpdatePayload {
    mechanical: number
}

interface QuickhelpPendingUpdatePayload {
    quickhelp: number
}

export type ReadNotfications = ShoppePendingUpdatePayload | CarspaPendingUpdatePayload | MechanicalPendingUpdatePayload | QuickhelpPendingUpdatePayload

export class NotificationSetAction implements Action {
    readonly type: NotificationActionType

    constructor( public payload: Notification | Notification[] | ReadNotfications ) {}
}

export type NotificationAction = NotificationSetAction