import { Notification } from "../models/notifications.model";
import { NotificationAction, NotificationActionType, ReadNotfications } from "../actions/notifications.action";

const initialState: Array<Notification> = [];

export function notificationReducer(
    state: Array<Notification> = initialState,
    action: NotificationAction
) : Array<Notification>  {
    switch(action.type) {
        case NotificationActionType.SET_NOTIFICATIONS:
            if(instanceOfNotificationArray(action.payload))
            return action.payload;
            else
            return state;
        case NotificationActionType.ADD_NOTIFICATION:
            if(instanceOfNotification(action.payload))
            return [...state, action.payload];
            else
            return state;
        case NotificationActionType.READ_ALL:
            if(instanceofReadNotifications(action.payload)) {
                state.forEach((noti, i) => {
                    const newPayload = [...state]
                    const asset = noti.assetType.toLowerCase()
                    switch(noti.assetType) {
                        case "Shoppe":
                            if(action.payload[asset])
                            newPayload[i] = {
                                ...noti,
                                read: true
                            }
                            return newPayload;
                        case "Carspa":
                            if(action.payload[asset])
                            newPayload[i] = {
                                ...noti,
                                read: true
                            }
                            return newPayload;
                        case "Mechanical":
                            if(action.payload[asset])
                            newPayload[i] = {
                                ...noti,
                                read: true,
                            }
                            return newPayload;
                        case "Quickhelp":
                            if(action.payload[asset])
                            newPayload[i] = {
                                ...noti,
                                read: true,
                            }
                            return newPayload;
                        default:
                            return state;
                    }
                })
            } else {
                return state;
            }
        default:
            return state;
    }
}

function instanceOfNotification(object): object is Notification {
    return object;
}

function instanceOfNotificationArray(object): object is Array<Notification> {
    return object;
}

function instanceofReadNotifications(object): object is ReadNotfications {
    return object
}