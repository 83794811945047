import { Action } from "@ngrx/store";
import { Products } from "../models/product.model";

export enum ProductsActionType {
    SET_PRODUCTS = 'Set products',
    CLEAR_PRODUCTS = 'Clear products'
}

export class ProductsAction implements Action {
    readonly type: ProductsActionType

    constructor( public payload: Products) {}
}