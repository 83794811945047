import { Action } from "@ngrx/store";
import { OrderStatus } from "../models/order-status";

export enum OrderActionType {
    SET_ORDER = 'Set pending order counts',
    UPDATE_ORDER = 'Update any order counts',
    UPDATE_PENDING = 'Update pending & total order counts',
    UPDATE_NEW = 'Update new & total order counts',
    RESET_NEW = 'Reset new',
}

interface ShoppePendingUpdatePayload {
    shoppe: number
}

interface CarspaPendingUpdatePayload {
    carspa: number
}

interface MechanicalPendingUpdatePayload {
    mechanical: number
}

interface QuickhelpPendingUpdatePayload {
    quickhelp: number
}

interface AllPendingUpdatePayload {
    shoppe: number,
    carspa: number,
    mechanical: number,
    quickhelp: number,
}

export type PendingUpdatePayload = AllPendingUpdatePayload

export class OrderActionClass implements Action {
    readonly type: OrderActionType

    constructor( public payload: OrderStatus | PendingUpdatePayload ) {}
}

export type OrderAction = OrderActionClass