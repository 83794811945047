import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as prodEnvironment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class ShoppeOrderService {
  constructor(private http: HttpClient) {}
  private adminShoppeUrl = 'v1.0/admin/order/';
  private partnerShoppeUrl = 'v1.0/partner/order/';
  private userApi = 'v1.0/user/id/';
  private shoppeUrl = 'v1.0/order/';
  private warehouseAdminUrl = 'v1.0/admin/warehouse';
  private delhiveryManifestApi = 'v1.0/admin/delhivery-manifest';

  getOrders(page, successcallback, failurecallback) {
    this.http.get(this.adminShoppeUrl + 'all?page='+ page||'1').subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  async getOneOrder(id: string, successcallback, failurecallback) {
    await this.http.get(this.adminShoppeUrl + 'id/' + id).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  getOneUser(id: string, successcallback, failurecallback) {
    this.http.get(this.userApi + id).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  cancelOrder(id: string, data, successcallback, failurecallback) {
    this.http.put(this.adminShoppeUrl + 'id/' + id, data).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  changeOrderStatus(id: string, data, successcallback, failurecallback) {
    this.http.put(this.adminShoppeUrl + 'id/' + id, data).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  orderByVendor(
    page: Number,
    status: string,
    successcallback,
    failurecallback
  ) {
    this.http
      .get(this.partnerShoppeUrl + 'vendor?page=' + page + '&status=' + status)
      .subscribe(
        (res) => {
          successcallback(res);
        },
        (err) => {
          failurecallback(err);
        }
      );
  }

  getAllPending(successcallback, failurecallback) {
    this.http
      .get(this.shoppeUrl + 'all/pending', {
        headers: new HttpHeaders({
          Authorization: `Bearer ${prodEnvironment.userToken}`,
        }),
      })
      .subscribe(
        (res) => {
          successcallback(res);
        },
        (err) => {
          failurecallback(err);
        }
      );
  }

  searchAndFilter(params) {
    return new Promise((resolve, reject) => {
      this.http.post(this.adminShoppeUrl + 'search-and-filter', params)
      .subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  downloadInvoice(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.adminShoppeUrl + 'get-invoice/id/' + id, { responseType: 'blob' })
      .subscribe(
        (res) => {
          this.downLoadFile('pexa_invoice_'+id, res, 'application/pdf');
          resolve(true);
        },
        (err) => reject(err)
      )
    })
  }

  downloadDHPackingSlip(id) {
      return new Promise((resolve, reject) => {
        this.http
          .get(this.adminShoppeUrl + 'get-delhivery-slip/id/' + id, {
            responseType: 'blob',
          })
          .subscribe(
            (res) => {
              this.downLoadFile('DHPackingSlip_'+id, res, 'application/pdf');
              resolve(true);
            },
            (err) => reject(err)
          );
      });
  }

  createDelhiveryManifest(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.delhiveryManifestApi, data)
      .subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  createDelhiveryPickup(data) {
    return new Promise((resolve, reject) => {
      this.http.patch(this.adminShoppeUrl + 'create-delhivery-pickup', data)
      .subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  getAllWarehouses() {
    return new Promise((resolve, reject) => {
      this.http.get(this.warehouseAdminUrl + '/all')
      .subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  getVendorWarehouses(vendorId) {
    return new Promise((resolve, reject) => {
      this.http.get(this.warehouseAdminUrl + '/vendor/id/' + vendorId)
      .subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

      /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
      downLoadFile(filename: string, data: any, type: string) {
        let file = new Blob([data], { type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file)
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
