import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { DeviceDetectorService } from 'ngx-device-detector';
import { select, Store } from '@ngrx/store';
import { State } from '../store/models/state.model';
import { FCMActionType } from '../store/actions/fcm.action';
import { environment as prodEnvironment } from 'src/environments/environment.prod';
import { DeviceInfo } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  NotificationActionType,
  ReadNotfications,
} from '../store/actions/notifications.action';
import {
  Notification,
  NotificationEventTypes,
} from '../store/models/notifications.model';
import { OrderActionType, PendingUpdatePayload } from '../store/actions/order-status.action';
import { take } from 'rxjs/operators';

// export interface DeviceInfo {
//   browser: string;
//   browser_version: string;
//   device: string;
//   os: string;
//   os_version: string;
//   userAgent: string;
// }

export enum Assets {
  shoppe = 'shoppe',
  carspa = 'carspa',
  mechanical = 'mechanical',
  quickhelp = 'quickhelp',
}

@Injectable({
  providedIn: 'root',
})
export class FCMService {
  public currentMessage = new BehaviorSubject({});
  private channel4Broadcast: BroadcastChannel = new BroadcastChannel('notification channel')
  constructor(
    private fireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private store: Store<State>,
    private toast: ToastrService,
  ) {
    this.fireMessaging.messaging.subscribe((_messaging: any) => {
      _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  deviceInfo: DeviceInfo;
  audioContext: AudioContext

  notificationApiUrl = 'v1.0/notification/';

  requestToken(): void {
    this.fireMessaging.requestToken.subscribe({
      next: (token) => {
        console.log('Fetched token successfully', token);
        this.sendToken(token);
      },
      error: (err) => {
        console.log('Fetching FCM token failed: ', err);
      },
    });
  }

  sendToken(token: string): void {
    const body = {
      token,
      deviceId: token,
      device: `${this.deviceInfo.os_version} ${this.deviceInfo.userAgent}`,
      deviceType: 'Web',
      userType: 'admin',
    };
    this.http
      .post(this.notificationApiUrl, body, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${prodEnvironment.userToken}`,
        }),
      })
      .subscribe(
        (res: any) => {
          console.log('api res', res);
          this.store.dispatch({
            type: FCMActionType.SET_FCM_DATA,
            payload: res.resultData,
          });
          this.listen();
        },
        (err) => {
          console.log('token create api error: ', err);
        }
      );
  }

  listen() {
    this.fireMessaging.messages.subscribe((payload: any) => {
      console.log('notification payload', payload);
      this.addNotification(payload);
    });
    this.channel4Broadcast.onmessage = ({ data }) => {
      const notification = JSON.parse(data.notification)
      console.log('notification after parsing broadcast', notification)
      this.addNotification_ready(notification)
      // this.updateOrderStatusForANotification(notification)
    }
  }

  setNotifications(notifications) {
    console.log('called setNotifications')
    const notificationData: Notification = notifications.map((notification) => {
      return {
        id: notification._id,
        read: notification.read,
        title: notification.title,
        image: notification.imageURL[0],
        assetType: notification.assetType,
        date: notification.date,
        eventType: notification.eventType,
      };
    });
    this.store.dispatch({
      type: NotificationActionType.SET_NOTIFICATIONS,
      payload: notificationData,
    });
    this.getOrderStatusForAllNotifications(notificationData)
  }

  async addNotification(payload) {
    console.log('payload', payload)
    if(payload.data.eventType === 'order-placed') {
      this.playSound()
    }
    this.store.pipe(
      select('notifications'),
      take(1)
    )
    .toPromise()
    .then((notifications) => {
      const ids = notifications.filter(noti => noti.id === payload.data._id)
      console.log('duplicate ids', ids)
      if(!ids.length) {
        const notification = {
          id: payload.data._id,
          read: false,
          title: payload.notification.title,
          image: payload.data.image,
          assetType: payload.data.assetType,
          date: payload.data.date,
          eventType: payload.data.eventType,
        };
        console.log('notification', notification)
        this.store.dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          payload: notification,
        });
        this.updateOrderStatusForANotification(notification)
      }
    })
  }

  addNotification_ready(notification) {
    if(notification.eventType === 'order-placed') {
      this.playSound()
    }
    console.log('payload', notification)
    this.store.pipe(
      select('notifications'),
      take(1)
    )
    .toPromise()
    .then((notifications) => {
      console.log('notification', notification)
      const ids = notifications.filter(noti => noti.id === notification.id)
      console.log('duplicate ids', ids)
      if(!ids.length) {
        this.store.dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          payload: notification,
        });
        this.updateOrderStatusForANotification(notification)
      }
    })
    .catch((err) => console.log('err', err))
  }

  async getOrderStatusForAllNotifications(notifications) {
    // const notifications = await this.store.pipe(
    //   select('notifications'),
    //   take(1)
    // )
    // .toPromise()
    console.log('called getOrderStatus')
    const orderStatus = {
      shoppe: 0,
      carspa: 0,
      mechanical: 0,
      quickhelp: 0,
      total: 0
    }
    notifications.forEach((notification) => {
      if (notification.eventType === NotificationEventTypes.placed) {
        switch (notification.assetType) {
          case 'Shoppe': {
            if (!notification.read) {
              orderStatus.shoppe += 1;
              orderStatus.total += 1;
            }
            break;
          }
          case 'Carspa': {
            if (!notification.read) {
              orderStatus.carspa += 1;
              orderStatus.total += 1;
            }
            break;
          }
          case 'Mechanical': {
            if (!notification.read) {
              orderStatus.mechanical += 1;
              orderStatus.total += 1;
            }
            break;
          }
          case 'Quickhelp': {
            if (!notification.read) {
              orderStatus.quickhelp += 1;
              orderStatus.total += 1;
            }
            break;
          }
        }
      }
    })
    this.store.dispatch({
      type: OrderActionType.UPDATE_NEW,
      payload: orderStatus,
    })
  }

  async updateOrderStatusForANotification(notification) {
    if (notification.eventType === NotificationEventTypes.placed) {      
      const payload = {}
      payload[notification.assetType.toLowerCase()] = {
        new: 1,
        pending: 1,
      }
      console.log('payload for update order ', payload)
      this.store.dispatch({
        type: OrderActionType.UPDATE_ORDER,
        payload
      })
    } else if (
      notification.eventType === NotificationEventTypes.completed ||
      notification.eventType === NotificationEventTypes.cancelled
    ) {
      const payload = {}
      payload[notification.assetType.toLowerCase()] = {
        new: 0,
        pending: -1,
      }
      console.log('inside negative update, payload => ', payload)
      this.store.dispatch({
        type: OrderActionType.UPDATE_ORDER,
        payload
      })
    }
  }

  fetchNotifications() {
    console.log('called fetchNotifications')
    this.http
      .get(this.notificationApiUrl + 'admin/unread', {
        headers: new HttpHeaders({
          Authorization: `Bearer ${prodEnvironment.userToken}`,
        }),
      })
      .subscribe(
        async ({ resultData }: any) => {
          await this.setNotifications(resultData);
        },
        (err) => {
          this.toast.error(err.message);
        }
      );
  }

  readNotifications(notificationIds: Array<string>, asset: Assets) {
    this.http
      .put(
        this.notificationApiUrl + 'admin/mark-as-read',
        {
          notificationIds,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${prodEnvironment.userToken}`,
          }),
        }
      )
      .subscribe(
        () => {
          const payload = {} as ReadNotfications | PendingUpdatePayload;
          payload[asset] = 1;
          console.log('dispatch read all', payload)
          this.store.dispatch({
            type: NotificationActionType.READ_ALL,
            payload,
          });

          this.store.dispatch({
            type: OrderActionType.RESET_NEW,
            payload
          })
        },
        (err) => this.toast.error(err.message)
      );
  }

  updateOrders(payload) {
    const newPayload = {};
    if (
      payload.data.eventType === 'order-completed' ||
      payload.data.eventType === 'order-cancelled'
    ) {
      newPayload[payload.data.assetType.toLowerCase()] = {
        new: 0,
        pending: -1,
      };
      this.store.dispatch({
        type: OrderActionType.UPDATE_ORDER,
        payload: newPayload,
      });
    } else if (payload.data.eventType === 'order-placed') {
      newPayload[payload.data.assetType.toLowerCase()] = {
        new: 1,
        pending: 1,
      };
      this.store.dispatch({
        type: OrderActionType.UPDATE_PENDING,
        payload: newPayload,
      });
    }
  }

  soundOn() {
    this.audioContext = new AudioContext()
  }

  playSound() {
    new Audio('../assets/notification/alert.mp3').play()
  }
}
