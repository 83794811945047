import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as prodEnvironment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = 'v1.0/user';
  apiUrl2 = 'v1.0/role';
  apiUrl3 = 'v1.0/franchise';
  apiUrl4 = 'v1.0/address';
  apiUrl5 = 'v1.0/auth';
  adminApiUrl = 'v1.0/admin/user';
  walletApi = 'v1.0/admin/wallet';

  constructor(private http: HttpClient) {}

  async getAllRole(successcallback, failurecallback) {
    await this.http.get(this.apiUrl2 + '/').subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  getById(id: string, successcallback, failurecallback) {
    this.http.get(this.apiUrl2 + '/id/' + id).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  getuserbyId(id: string, successcallback, failurecallback) {
    this.http.get(this.adminApiUrl + '/id/' + id).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  save(data, successcallback, failurecallback) {
    this.http.post(this.apiUrl3 + '/', data).subscribe(
      (res) => {
        console.log(res);
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  addAddress(data: any, successcallback, failurecallback) {
    this.http.post(this.apiUrl4 + '/', data).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  delete(id, successcallback, failurecallback) {
    this.http.delete(this.apiUrl3 + '/id/' + id).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  update(id, data: any, successcallback, failurecallback) {
    this.http.put(this.apiUrl3 + '/id/' + id, data).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  changePassword(data, successcallback, failurecallback) {
    this.http.post(this.apiUrl5 + '/change-password', data).subscribe(
      (res) => {
        successcallback(res);
      },
      (err) => {
        failurecallback(err);
      }
    );
  }

  getAllUsers(): Observable<any> {
    return this.http.get(
      this.apiUrl3 +
        '?fields=phone,name,email,username,isActive,role,carType,otpCode,otpCreatedDate,loginDate,provides,origin,createdAt,updatedAt,_id,__v,status',
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${prodEnvironment.userToken}`,
        }),
      }
    );
  }

  filter(params, successcallback, failurecallback) {
    this.http.post(this.adminApiUrl + '/filter', params).subscribe(
      (res) => successcallback(res),
      (err) => failurecallback(err),
    )
  }

  topupWallet(params) {
    return new Promise((resolve, reject) => {
      this.http.post(this.walletApi + '/create-transaction', params).subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  walletTopupRequest(params) {
    return new Promise((resolve, reject) => {
      this.http.patch(this.walletApi + '/topup', params).subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }

  walletTopupConfirm(params) {
    return new Promise((resolve, reject) => {
      this.http.post(this.walletApi + '/confirm-topup', params).subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      )
    })
  }
}
