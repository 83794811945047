import { User } from "../models/user.model";
import { UserAction, UserActionType } from "../actions/user.action";

const initialState: Array<User> = [
    
];

export function userReducer(
    state: Array<User> = initialState,
    action: UserAction
) {
    switch(action.type) {
        case UserActionType.SET_USERS:
            return action.payload;
        default:
            return state;
    }
}