import { OrderStatus } from "../models/order-status";
import { OrderAction, OrderActionType, PendingUpdatePayload } from "../actions/order-status.action";

const initialState: OrderStatus = {
    shoppe: {
        new: 0,
        pending: 0,
    },
    carspa: {
        new: 0,
        pending: 0,
    },
    mechanical: {
        new: 0,
        pending: 0,
    },
    quickhelp: {
        new: 0,
        pending: 0,
    },
    total: {
        new: 0,
        pending: 0,
    }
};

export function orderStatusReducer(
    state: OrderStatus = initialState,
    action: OrderAction,
): OrderStatus {
    if(action.payload) {
        switch(action.type) {
            case OrderActionType.SET_ORDER:
                if(instanceOfOrderStatus(action.payload))
                return action.payload;
                else
                return state;
            case OrderActionType.UPDATE_ORDER:
                if(instanceOfOrderStatus(action.payload)) {
                    const orderStatus = {}
                    Object.keys(action.payload)
                    .forEach((key) => {
                        orderStatus[key] = {
                            new: state[key]?.new + action.payload[key]?.new,
                            pending: state[key]?.pending + action.payload[key]?.pending,
                        }
                        orderStatus["total"] = {
                            new: state["total"]?.new + action.payload[key]?.new,
                            pending: state["total"]?.pending + action.payload[key]?.pending,
                        }
                    })
                    return {
                        ...state,
                        ...orderStatus,
                    }
                } else {
                    return state;
                }
            case OrderActionType.UPDATE_PENDING:
                if(instanceOfPendingUpdatePayload(action.payload)) {
                    return {
                        shoppe: {
                            new: state.shoppe.new,
                            pending: action.payload.shoppe,
                        },
                        carspa: {
                            new: state.carspa.new,
                            pending: action.payload.carspa,
                        },
                        mechanical: {
                            new: state.mechanical.new,
                            pending: action.payload.mechanical,
                        },
                        quickhelp: {
                            new: state.quickhelp.new,
                            pending: action.payload.quickhelp,
                        },
                        total: {
                            new: state.total.new,
                            pending: action.payload.shoppe + action.payload.carspa + action.payload.mechanical + action.payload.quickhelp,
                        }
                    }
                } else {
                    return state;
                }
            case OrderActionType.UPDATE_NEW:
                if(instanceOfPendingUpdatePayload(action.payload)) {
                    return {
                        shoppe: {
                            new: action.payload.shoppe,
                            pending: state.shoppe.pending,
                        },
                        carspa: {
                            new: action.payload.carspa,
                            pending: state.carspa.pending,
                        },
                        mechanical: {
                            new: action.payload.mechanical,
                            pending: state.mechanical.pending,
                        },
                        quickhelp: {
                            new: action.payload.quickhelp,
                            pending: state.quickhelp.pending,
                        },
                        total: {
                            new: action.payload.shoppe + action.payload.carspa + action.payload.mechanical + action.payload.quickhelp,
                            pending: state.total.pending,
                        }
                    }
                } else {
                    return state;
                }
            case OrderActionType.RESET_NEW:
                if(instanceOfPendingUpdatePayload(action.payload)) {
                    const updateNew = {
                        total: {
                            new: state.total.new,
                            pending: state.total.pending,
                        }
                    }
                    Object.keys(action.payload)
                    .forEach((key) => {
                        updateNew[key] = {
                            new: 0,
                            pending: state[key]?.pending
                        }
                        updateNew["total"] = {
                            new: updateNew["total"]?.new - state[key]?.new,
                            pending: state[key]?.pending
                        }
                    })
                    const newPayload = {
                        ...state,
                        ...updateNew
                    }
                    console.log('inside reset new, action.payload', action.payload, 'newPayload ', newPayload, 'updateNew', updateNew)
                    if(instanceOfOrderStatus(newPayload))
                    return newPayload
                    else 
                    return state
                } else {
                    return state;
                }
            default:
                return state
        }
    }
}

function instanceOfOrderStatus(object: any): object is OrderStatus {
    return object
}

function instanceOfPendingUpdatePayload(object: any): object is PendingUpdatePayload {
    return object
}