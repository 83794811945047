import { Action } from "@ngrx/store";

export enum Unapproved_Worker_Count_Action_Type_Enum {
    SET_COUNT = 'Set unapproved worker counts',
    CLEAR_COUNT = 'Clear unapproved worker counts',
    DECREMENT_COUNT = 'Decrement unapproved worker counts',
    INCREMENT_COUNT = 'Increment unapproved worker counts',
}

export interface Worker_Count_Payload {
    carspa: number | null;
    mechanical: number | null;
    quickhelp: number | null;
}

export class Unapproved_Worker_Count_Action implements Action {
    readonly type: Unapproved_Worker_Count_Action_Type_Enum

    constructor( public payload: Worker_Count_Payload) {}
}