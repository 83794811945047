import { FcmData } from "../models/fcm.model";
import { FCMAction, FCMActionType } from "../actions/fcm.action";

const initialState = {} as FcmData

export function fcmReducer(
    state: FcmData = initialState,
    action: FCMAction
) {
    switch(action.type) {
        case FCMActionType.SET_FCM_DATA:
            return action.payload;
        default:
            return state;
    }
}