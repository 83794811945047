import { Products } from "../models/product.model";
import { ProductsAction, ProductsActionType } from "../actions/product.action";

const initialState = [];

export function productReducer(
    state: Products = initialState,
    action: ProductsAction
): Products {
    switch(action.type) {
        case ProductsActionType.SET_PRODUCTS:
            return action.payload;
        case ProductsActionType.CLEAR_PRODUCTS:
            return initialState;
        default:
            return state;
    }
}