import {
  PageAction,
  PageActionType,
  PayloadTypes,
} from '../actions/pages.action';
import { Pages } from '../models/pages.model';

const initialState: Pages = {
  shoppeOrder: 1,
  carspaOrder: 1,
  mechanicalOrder: 1,
  quickhelpOrder: 1,
};

export function pagesReducer(
  state: Pages = initialState,
  action: PageAction
): Pages {
  switch (action.type) {
    case PageActionType.UPDATE_PAGE:
      if (action.payload.type === PayloadTypes.SHOPPE_ORDER) {
        return { ...state, shoppeOrder: action.payload.page };
      } else if (action.payload.type === PayloadTypes.CARSPA_ORDER) {
        return { ...state, carspaOrder: action.payload.page };
      } else if (action.payload.type === PayloadTypes.MECHANICAL_ORDER) {
        return { ...state, mechanicalOrder: action.payload.page };
      } else if (action.payload.type === PayloadTypes.QUICKHELP_ORDER) {
        return { ...state, quickhelpOrder: action.payload.page };
      } else {
        return state;
      }
    default:
      return state;
  }
}
