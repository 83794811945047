import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export enum UserActionType {
    SET_USERS = 'Set Users',
}

export class SetUserAction implements Action {
    readonly type = UserActionType.SET_USERS;
    
    constructor(public payload: User[]) {}
}

export type UserAction = SetUserAction;