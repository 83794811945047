// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBO5oJnzVjmM2fF8jj9aNdjJGli6DuEifw",
    authDomain: "carclenx.firebaseapp.com",
    databaseURL: "https://carclenx.firebaseio.com",
    projectId: "carclenx",
    storageBucket: "carclenx.appspot.com",
    messagingSenderId: "968510000223",
    appId: "1:968510000223:web:e2de53789c5313a159dfea",
    measurementId: "G-329STDLDJN",
    vapidKey: "BNh6YWRrZL3_l6I1rhzLcsu-r0zZUNPVLL0eEfYb5n-yINJeoR_d5pPj1-zdPCfpHCPAL0aPTxk7OT1VqfUSSDs"
  },
  // firebase: {
  //   apiKey: "AIzaSyCZSnrX5d2RNJtNVDj3hGZnpGHDegBDlko",
  //   authDomain: "pushnotification-e3c09.firebaseapp.com",
  //   projectId: "pushnotification-e3c09",
  //   storageBucket: "pushnotification-e3c09.appspot.com",
  //   messagingSenderId: "34934840311",
  //   appId: "1:34934840311:web:ab4f8f1a68164820ec2cf2",
  //   measurementId: "G-QV6Y3L2RY2"
  // },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  apiUrl: 'http://localhost:4000/v1.0/',
  userToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoiNjIyMzU0Njk3MjgyNzEwYzY4MGQxNjE0IiwidXNlcm5hbWUiOiJqYXlhc3VyeWEiLCJyb2xlIjpbeyJfaWQiOiI2MjE3ZWZjN2IxMjRhYTZmYjQ5NGNlMTMiLCJuYW1lIjoiYWRtaW4ifSx7Il9pZCI6IjYyMThkNjY2NzI4MjcxMGM2ODA5MjZkNyIsIm5hbWUiOiJmcmFuY2hpc2UiLCJfX3YiOjB9LHsiX2lkIjoiNjIxN2M0MWI0MTczZTZlYzE3YzU3OWUyIiwibmFtZSI6ImN1c3RvbWVyIn1dLCJuYW1lIjoiYW5hbmQgcyIsInBob25lIjoiNzAyNTIzMTM2NiJ9LCJpYXQiOjE2NDcwMDU3NDcsImV4cCI6MTk2MjU4MTc0N30.mQ0w4CkIO0yK2nPm6Lro8utUo4iGnFNCyOnxuoFt_uU',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
