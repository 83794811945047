enum AssetTypes {
    shoppe = "Shoppe",
    carspa = "Carspa",
    mechanical = "Mechanical",
    quickhelp = "Quickhelp",
}

export enum NotificationEventTypes {
    placed = "order-placed",
    completed = "order-completed",
    cancelled = "order-cancelled",
}

export interface Notification {
    id: string;
    read: boolean;
    title: string;
    image: string;
    assetType: AssetTypes;
    eventType: NotificationEventTypes;
    date: Date;
}