import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuard } from './_guards/role.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
const appRoutes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },

    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'error', loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
      , canActivate: [AuthGuard] },
      {
        path: 'authentication', loadChildren: () => import('../app/content/full-pages/authentication/authentication.module').then(m =>
          m.AuthenticationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: () => import('../app/content/full-pages/others/others.module').then(m => m.OthersModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard, RoleGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard, RoleGuard] },
      { path: 'dev/dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
      , canActivate: [AuthGuard,  RoleGuard] },
      {
        path: 'components', loadChildren: () => import('../app/content/ngbbootstrap/components.module').then(m => m.ComponentsModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      { path: 'todo-app', loadChildren: () => import('../app/content/applications/todo-app/todo-app.module').then(m => m.TodoAppModule)
      , canActivate: [AuthGuard, RoleGuard] },
      { path: 'chats', loadChildren: () => import('../app/content/applications/chat/chats.module').then(m => m.ChatsModule),
        canActivate: [AuthGuard, RoleGuard]},
      { path: 'email', loadChildren: () => import('../app/content/applications/email/email.module').then(m => m.EmailModule)
      , canActivate: [AuthGuard, RoleGuard] },
      {
        path: 'calender', loadChildren: () => import('../app/content/applications/calender/calender.module').then(m => m.CalenderModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'kanban', loadChildren: () => import('../app/content/applications/kanban-app/kanban-app.module').then(m => m.KanbanAppModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path:'worker', loadChildren:() => import('./content/applications/carspa-worker/carspa-worker.module').then(m => m.CarspaWorkerModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'make', loadChildren: () => import('../app/content/applications/make/make.module').then(m => m.MakeModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path:'carspacat', loadChildren:()=> import('./content/applications/carspa-category/carspa-category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path:'carspaservice', loadChildren:()=> import('../app/content/applications/carspa-service/carspaservice.module').then(m => m.CarspaserviceModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'model', loadChildren: () => import('../app/content/applications/model/model.module').then(m => m.ModelModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'prodcat', loadChildren: () => import('./content/applications/product-cat/product-cat-module').then(m => m.ProductCatModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'prodsubcat', loadChildren: () => import('./content/applications/product-subcat/product-subcat-module').then(m => m.ProductSubcatModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'prod', loadChildren: () => import('../app/content/applications/product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'vendor', loadChildren: () => import('../app/content/applications/vendor/vendor.module').then(m => m.VendorModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'warehouse', loadChildren: () => import('../app/content/applications/warehouse/warehouse.module').then(m => m.WarehouseModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'productoffer', loadChildren: () => import('../app/content/applications/product-offer/product-offer.module').then(m => m.ProductOfferModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'orders/shoppe', loadChildren: () => import('./content/applications/shoppe-order/shoppe-order.module').then(m => m.ShoppeModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path:'orders/mechanical', loadChildren:()=> import ('./content/applications/mechanical-orders/mechanical-orders.module').then(m=>m.MechOrdersModule),
        canActivate:[AuthGuard, RoleGuard],
      }, 
      {
        path:'orders/quickhelp', loadChildren:()=> import ('../app/content/applications/quickhelp-order/quickhelp-order.module').then(m=>m.QuickhelpOrderModule),
        canActivate:[AuthGuard, RoleGuard],
      }, 
      {
        path:'orders/carspa', loadChildren:() => import ('../app/content/applications/carspa-order/carspa-order.module').then(m=>m.CarspaOrderModule),
        canActivate:[AuthGuard, RoleGuard],
      },
      {
        path:'user', loadChildren: () => import('../app/content/applications/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'registration', loadChildren: () => import('../app/content/applications/partner-application/partner-application.module').then(m => m.PartnerApplicationModule),
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path:'branch', loadChildren: () => import('../app/content/applications/carspa-branch/carspa-branch.module').then(m => m.CarspaBranchModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path:'mechanicalbranch',loadChildren: () => import('../app/content/applications/mechanical-branch/mechanical-branch.module').then(m=>m.MechanicalBranchModule),
        canActivate:[AuthGuard, RoleGuard]
      },{
        path:'mechanicalcategory',loadChildren:()=>import('../app/content/applications/mechanical-category/mechanical-category.module').then(m=>m.MechanicalCategoryModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'mechanicalservice',loadChildren:()=> import('../app/content/applications/mechanical-service/mechanical-service.module').then(m=>m.MechanicalServiceModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'mechanicalworker',loadChildren:()=>import('../app/content/applications/mechanical-worker/mechanical-worker.module').then(m=>m.MechanicalWorkerModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'mechanicaloffer',loadChildren:()=>import('../app/content/applications/mechanical-offer/mechanical-offer.module').then(m=>m.MechanicalOfferModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'quickhelpoffer',loadChildren:()=>import('../app/content/applications/quickhelp-offer/quickhelp-offer.module').then(m=>m.QuickhelpOfferModule),
        canActivate:[AuthGuard, RoleGuard] 
      },
      {
        path:'carspaoffer',loadChildren:()=>import('../app/content/applications/carspa-offer/carspa-offer.module').then(m=>m.CarspaOfferModule),
        canActivate:[AuthGuard, RoleGuard] 
      },
      {
        path:'quickhelpbranch',loadChildren:()=>import('../app/content/applications/quickhelp-branch/quickhelp-branch.module').then(m=>m.QuickhepBranchModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'quickhelpcategory',loadChildren:()=>import('../app/content/applications/quickhelp-category/quickhelp-category.module').then(m=>m.QuickhelpCategoryModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'quickhelpservice',loadChildren:()=>import('../app/content/applications/quickhelp-service/quickhelp-service.module').then(m=>m.QuickhelpServiceModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'quickhelpworker',loadChildren:()=>import('../app/content/applications/quickhelp-worker/quickhelp-worker.module').then(m=>m.QuickhelpWorkerModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'notification-center',loadChildren:()=>import('../app/content/applications/notification-center/notification-center.module').then(m=>m.NotificationCenterModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'globalsettings', loadChildren:()=> import('../app/content/applications/global-settings/global-settings.module').then(m=> m.GlobalSettingsModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path:'coupon',loadChildren:()=>import('../app/content/applications/coupon/coupon.module').then(m =>m.CouponModule),
        canActivate:[AuthGuard, RoleGuard]
      },
      {
        path: 'affiliate', loadChildren: () => import('../app/content/applications/affiliate/affiliate.module').then(m => m.AffiliateModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'dashboard', loadChildren: () => import('../app/content/applications/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'treeview', loadChildren: () => import('../app/content/extra-components/treeview/treeview.module').then(m => m.TreeViewModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      { path: 'chartjs', loadChildren: () => import('../app/content/charts-maps/chartjs/chartjs.module').then(m => m.ChartjsModule)
      , canActivate: [AuthGuard, RoleGuard] },
      {
        path: 'form-elements', loadChildren: () => import('../app/content/forms/form-elements/form-elements.module').then(m =>
          m.FormElementsModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'form-layouts', loadChildren: () => import('../app/content/forms/form-layouts/form-layouts.module').then(m =>
          m.FormLayoutsModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'form-wizard', loadChildren: () => import('../app/content/forms/form-wizard/form-wizard.module').then(m =>
          m.FormWizardModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'form-repeater', loadChildren: () => import('../app/content/forms/form-repeater/form-repeater.module').then(m =>
          m.FormRepeaterModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'ngchartist', loadChildren: () => import('../app/content/charts-maps/ngchartist/ngchartist.module').then(m =>
          m.NgchartistModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'boostraptables', loadChildren: () => import('../app/content/table/boostraptables/boostraptables.module').then(m =>
          m.BoostraptablesModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'datatables', loadChildren: () => import('../app/content/table/datatables/datatables.module').then(m => m.DatatablesModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'datatablesext', loadChildren: () => import('../app/content/table/datatablesext/datatablesext.module').then(m =>
          m.DatatablesextModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      { path: 'icons', loadChildren: () => import('../app/content/icons/icons.module').then(m => m.IconsModule), canActivate: [AuthGuard, RoleGuard] },
      { path: 'cards', loadChildren: () => import('../app/content/cards/cards.module').then(m => m.CardsModule), canActivate: [AuthGuard, RoleGuard] },
      { path: 'maps', loadChildren: () => import('../app/content/maps/maps.module').then(m => m.MapsModule), canActivate: [AuthGuard, RoleGuard] },
      { path: 'invoice', loadChildren: () => import('../app/content/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      , canActivate: [AuthGuard, RoleGuard] },
      {
        path: 'timelines', loadChildren: () => import('../app/content/pages/timelines/timelines.module').then(m => m.TimelinesModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      { path: 'user', loadChildren: () => import('../app/content/pages/user/user.module').then(m => m.UserModule)
      , canActivate: [AuthGuard, RoleGuard] },
      { path: 'gallery', loadChildren: () => import('../app/content/pages/gallery/gallery.module').then(m => m.GalleryModule)
      , canActivate: [AuthGuard, RoleGuard] },
      { path: 'news-feed', loadChildren: () => import('../app/content/pages/news-feed/news-feed.module').then(m => m.NewsFeedModule)
      , canActivate: [AuthGuard, RoleGuard] },
      { path: 'dropzone', loadChildren: () => import('../app/content/pages/dropzone/dropzone.module').then(m => m.DropzoneModule)
      , canActivate: [AuthGuard, RoleGuard] },
      {
        path: 'social-feed', loadChildren: () => import('../app/content/pages/social-feed/social-feed.module').then(m =>
          m.SocialFeedModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      { path: 'search', loadChildren: () => import('../app/content/pages/search/search.module').then(m => m.SearchModule)
      , canActivate: [AuthGuard, RoleGuard] },
      {
        path: 'advanceCards', loadChildren: () => import('../app/content/advance-cards/advance-cards.module').then(m =>
          m.AdvanceCardsModule),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'extraComponents', loadChildren: () => import('../app/content/extra-components/extra-components.module').then(m =>
          m.ExtraComponentsModule),
        canActivate: [AuthGuard, RoleGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'changelog' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
