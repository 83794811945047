import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";

@Injectable()
export class NavbarService {
  private mouseInMenuRegion = false;
  private fixedMenu = false;
  private fetchUnapprovedWorkerCountUrl = 'v1.0/admin/worker/all-unapproved-count';
  constructor(
    private http: HttpClient
  ) {}

  isMouseInRegion() {
    return this.mouseInMenuRegion;
  }

  setMouseInRegion(flag) {
    this.mouseInMenuRegion = flag;
  }

  isFixedMenu() {
    return this.fixedMenu;
  }

  setFixedMenu(flag) {
    this.fixedMenu = flag;
  }

  getUnapprovedWorkerCounts(successcallback, failurecallback) {
    this.http.get(this.fetchUnapprovedWorkerCountUrl, { headers: {
      Authorization: `Bearer ${environment.userToken}`
    }}).subscribe(
      (res) => successcallback(res),
      (err) => failurecallback(err),
    )
  }
}
