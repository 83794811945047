import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) {}

  loginURL = 'v1.0/auth/sign-in'

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      
      this.http.post(this.loginURL,value).subscribe(
      res=>{
        resolve(res)
    },
    err=>{
      reject(err)
    }
      )
      /* resolve(value) */
      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(value.email, value.password)
      //   .then(
      //     res => {
      //       resolve(res);
      //     },
      //     err => reject(err)
      //   );
    });
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('remember');
        this.afAuth.auth.signOut();
        resolve();
      } else {
        localStorage.removeItem('currentUser');
        resolve();
      }
    });
  }
}
