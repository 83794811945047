import { Action } from "@ngrx/store";
import { FcmData } from "../models/fcm.model";

export enum FCMActionType {
    SET_FCM_DATA = 'Set FCM Data'
}

export class FCMSetAction implements Action {
    readonly type = FCMActionType.SET_FCM_DATA;

    constructor( public payload: FcmData) {}
}

export type FCMAction = FCMSetAction