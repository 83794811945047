import { Unapproved_Worker_Count } from "../models/unapproved-worker-count";
import { Unapproved_Worker_Count_Action, Unapproved_Worker_Count_Action_Type_Enum } from "../actions/unapproved-worker-count.action";

const initialState: Unapproved_Worker_Count = {
    carspa: 0,
    mechanical: 0,
    quickhelp: 0,
};

export function unapprovedWorkerCountsReducer(
    state: Unapproved_Worker_Count = initialState,
    action: Unapproved_Worker_Count_Action
): Unapproved_Worker_Count {
    switch(action.type) {
        case Unapproved_Worker_Count_Action_Type_Enum.SET_COUNT:
            {
                const counts = { ...state };
                Object.keys(action.payload).forEach(service => {
                    if(action.payload[service] !== null) {
                        counts[service] = action.payload[service];
                    }
                })
                return counts;
            }
        case Unapproved_Worker_Count_Action_Type_Enum.CLEAR_COUNT:
            {
                const counts = { ...state };
                Object.keys(action.payload).forEach(service => {
                    if(action.payload[service] !== null) {
                        counts[service] = 0;
                    }
                })
                return counts;
            };
        case Unapproved_Worker_Count_Action_Type_Enum.DECREMENT_COUNT:
            {
                const counts = { ...state };
                Object.keys(action.payload).forEach(service => {
                    if(action.payload[service] !== null) {
                        counts[service] -= 1;
                    }
                })
                return counts;
            }
        case Unapproved_Worker_Count_Action_Type_Enum.INCREMENT_COUNT:
            {
                const counts = { ...state };
                Object.keys(action.payload).forEach(service => {
                    if(action.payload[service] !== null) {
                        counts[service] += 1;
                    }
                    console.log('counts', counts)
                })
                return counts;
            }
        default:
            return state;
    }
}